import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

const store = new Vuex.Store({
    state: {
        walletConnected: false,
        wallet: "",
    },
    getters: {
        wallet: state => state.wallet
    },
    mutations: {
      connnectWallet (state) {
        state.walletConnected = true;
      },
      disconnnectWallet (state) {
        state.walletConnected = false;
      },
      saveWallet (state, wallet) {
        state.wallet = wallet;
      }
    },
    plugins: [new VuexPersistence().plugin]
});

export default store