<template>
  <router-view />
</template>

<style>
body, html {
  background-color: rgba(246, 243, 236, 0.3);
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;

  height: 100%;
  margin: 0;
  padding: 20px;
}

.row {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding:20px;
}
.col {
  width: 300px;
  margin-right:30px;
}
.btn {
  padding:10px 15px;
  background-color: rgb(189, 155, 104);
  color: #fff;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
}

.cover {
    display: flex;
}
.project-cover {
    width: 200px; height:200px;
    margin: 10px auto;
    border-radius: 10px;
}
.project {
    padding: 30px 30px;
    background-color: rgb(247, 244, 236);
    border-radius: 15px;
    position: relative;
}
.supply {
    color: #666;
    font-size: 14px;
    margin-top: 5px;
}
.CollaTag {
    padding:5px 9px;
    border-radius:3px;
    background-color: #d8e6cf;
    color:rgb(86, 132, 161);
    display: inline;
    position:absolute;
    top: 20px;
    left: -5px;
}
.desc {
    color:#666;
    font-size:15px;
    margin: 10px 0;
    line-height: 150%;
}
.release {
    color:rgb(113, 84, 59);

}
.buttons {
  display:flex;
  align-items: center;
  margin: 10px 0;
}

@media only screen and (max-width: 800px) {
  .project {
    max-width: 100% !important;
  }
}
</style>