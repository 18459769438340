<template>
  <div class="home">

    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="project">
            <div class="cover clickcover"  v-on:click="toUniwarp">
              <img src="../assets/0xUNIWARP.png" class="project-cover" />
            </div>
            <p class="title">#0 Uniwarp</p>
            <p class="fullTitle">Unicorn with its friends. </p>
            <p class="desc">The multiverse where no unicorn has gone before. Exclusive handmade.</p>
            <div>
              <a class="btn" target="_black" href="https://opensea.io/collection/uniwarp">
              OpenSea
            </a>
            <a class="btn" target="_black" href="https://twitter.com/uniwarp_">
              Twitter
            </a>
            </div>
          </div>
          
        </div>

        <div class="column">
          <div class="project">
            <div class="cover clickcover" v-on:click="toOrigin">
              <img src="../assets/ooup1.png" class="project-cover" />
            </div>
            <p class="title">#1 OOUP</p>
            <p class="fullTitle">Orgin Of The Uniwarp</p>
            <p class="supply">5555</p>
            <p class="desc">The begining of #Uniwarp. Few stories are told about them. Not too much is known about their highly developed civilization and their scientific discoveries. Their activity peaked a hundred thousand years ago, then suddenly ended.</p>
            <div>
              <a href="#" class="btn mintweb" v-on:click="toOrigin">
              Web Mint
              </a>
              <a class="btn" target="_black" href="https://opensea.io/collection/ooup">
              OpenSea
            </a>
            </div>
          </div>
        </div>

        <div class="column">
          <div class="project">
            <div class="CollaTag">Collaboration</div>
            <div class="cover clickcover" v-on:click="toSwab">
              <img src="../assets/swab.png" class="project-cover" />
            </div>
            <p class="title">SwabAlbum</p>
            <p class="supply">4004</p>
            <p class="desc">According to the Proxima Observatory, a year after the invasion, a culture known as "stabbing me" became popular. Humans affected by this culture, whether infected by the virus or not, would use swabs to stab their olfactory organ and digestive system in a manner of near-obsession…</p>
            <div>
              <a class="btn" target="_black" href="https://swabalbum.com/">
              Website
            </a>
            <a class="btn" target="_black" href="https://twitter.com/swabalbum">
              Twitter
            </a>
            <a class="btn" target="_black" href="https://mirror.xyz/0x25Dc0302A30e8Cc45829BFDc8ef8338F4fAa5C9f">
              Mirror
            </a>
            <!-- <a class="btn" target="_black" href="https://discord.gg/D8YQT3CYDf">
              Discord
            </a> -->
            </div>
          </div>
        </div>

        <div class="column">
          <div class="project">
          <img src="../assets/blur.png" class="project-cover" />
          <p class="title">#2 Undefined</p>
          <p class="release">To be announced.</p>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'PageHome',
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  },
  methods: {
    toOrigin () {
      this.$router.push('/ooup')
    },
    toSwab() {
      window.location = 'https://swabalbum.com'
    },
    toUniwarp() {
      window.location = 'https://opensea.io/collection/uniwarp'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mintweb {
  background-color: rgb(255, 136, 0);
}
.title {
    color: rgb(103, 103, 103);
    font-weight: bold;
    font-size: 1.2em;
    margin-top:10px;
}
.container {
}
.clickcover {
  cursor: pointer;
}
</style>
