<template>
    <div class="mint">
        <div class="modal" :class="{'is-active' : showMsg}">
            <div class="modal-background" @click="closeMsg"></div>
            <div class="modal-content">
                <div class="box msgbox">
                    {{msg}}
                </div>
            </div>
            <button class="modal-close is-large" aria-label="close" @click="closeMsg"></button>
        </div>

        <div class="container">
            <div class="row align-center">
                <a class="btn" href="../">
                Uniwarp Home
                </a>
            </div>
            <div class="row align-center">
                
                <div class="project">
                    <div class="cover">
                        <img src="../assets/ooup_cover.png" class="banner"/>
                    </div>
                        <p class="fullTitle">Orgin Of The Uniwarp</p>
                        <p class="desc">The begining of #Uniwarp. Few stories are told about them. Not too much is known about their highly developed civilization and their scientific discoveries. Their activity peaked a hundred thousand years ago, then suddenly ended.</p>
                   
                    <div class="buttons">
                        <input v-model="mintCount" class="mintCount" :disabled="isMinting" />
                        <a href="javascript:;" v-if="!currentAccount" class="btn mintBtn" @click="connectWallet">Connect Wallet</a>
                        <span v-else >
                            <a href="javascript:;" class="btn mintBtn" v-if="!isMinting" @click="callMint" >Mint</a>
                            <a href="javascript:;" class="btn mintBtn minting" v-else @click="CancelMint" >Please wait...</a>
                        </span>
                        <a class="btn" target="_black" href="https://opensea.io/collection/ooup">
                        OpenSea
                        </a>

                    </div>
                    <div v-if="totalSupply>0">
                        <p class="supply">{{totalSupply}}/5555</p>
                    </div>
                </div>

            </div>
        </div>
      
    </div>  
</template>

<script>
import contractAbi from "../assets/abi.json"
import {ethers} from 'ethers'

export default {
  name: 'PageOoup',
  data () {
    return {
        mintAddress: "0xcF79FdfeF585BC3C9D5A87fa251ffF6E5e299bef",
        currentAccount: null,
        currentContract : null,
        totalSupply: 0,
        msg: "",
        showMsg: false,
        isMinting: false,
        mintCount: 2,
    }
  },
  methods: {
    async checkBalance() {
        var mintContract
        try {
            if (typeof window.ethereum !== 'undefined') {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner()
                mintContract = new ethers.Contract(this.mintAddress, contractAbi, signer);
            } else {
                console.log('Ethereum object not found.')
            }
        } catch (error) {
            console.log(error);
            return false;
        }
        try {
            const supply = await mintContract.totalSupply()
            this.totalSupply = supply.toNumber()
        } catch (err) {
            console.log(err)
            if (err.code ===4001) {
                console.log("MetaMask action cancelled.")
            }
        }
    },
    async callMint() {
        var mintContract
        try {
            if (typeof window.ethereum !== 'undefined') {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner()
                mintContract = new ethers.Contract(this.mintAddress, contractAbi, signer);
            } else {
                this.showError('Ethereum not connected.')
            }
        } catch (error) {
            console.log(error);
            return false;
        }
        try {
            this.isMinting = true;
            const res = await mintContract.mint(this.mintCount);
            console.log(res)
            if (res.code == 4001) {
                this.showError("You've minted 2 items successfully.")
                this.totalSupply += this.mintCount
            }
            // modify totalsupply after mint

        } catch (err) {
            console.log(err.code == 4001)
            this.isMinting = false
            if (err.code == 4001) {
                this.showError("MetaMask action cancelled.")
            }
        } finally {
            this.isMinting = false
        }
    },
    async checkConnectedWalletExist() {
        const wallet = this.$store.getters.wallet || ""
        if (wallet.length>0) {
            this.currentAccount = wallet
            return true
        }
        //  else {
        //     try {
        //         const { ethereum } = window;
        //         if (!ethereum) {
        //             this.showError("Make sure you have metamask!")
        //             return false;
        //         } else {
        //             console.log("We have the ethereum object", ethereum);
        //         }
        //         const accounts = await ethereum.request({ method: "eth_accounts" });
        //         if (accounts.length !== 0) {
        //             const account = accounts[0];
        //             console.log("Found an authorized account:", account);
        //             this.currentAccount = account;
        //             this.$store.commit("connnectWallet")
        //             return true;
        //         } else {
        //             console.log("No authorized account found");
        //             return false;
        //         }
        //     } catch (error) {
        //         console.log(error);
        //         return false;
        //     }
        // }
        
    },
    async connectWallet () {
        try {
            const { ethereum } = window;
            if (!ethereum) {
                this.showError("Get MetaMask!");
                return;
            }
            const accounts = await ethereum.request({
                    method: "eth_requestAccounts",
            });
            console.log("Connected", accounts[0]);
            this.currentAccount = accounts[0];
            this.$store.commit("saveWallet", this.currentAccount)
            this.$store.commit("connnectWallet")
        } catch (error) {
            console.log(error);
        }
    },
    showError(txt) {
        this.msg = txt;
        this.showMsg = true;
    },
    closeMsg() {
        this.showMsg = false;
    }
  },
  mounted() {
    this.checkConnectedWalletExist();
    this.checkBalance();
  }
}
</script>

<style scoped>
.mintBtn {
    background: #c52727;
    border: 1px solid #a33333;
}
.banner {
    max-width:100%;
    margin-bottom: 20px;
}
.row {
    justify-content: center;
}
.msgbox {
    padding: 50px 50px;
}
.mintCount {
    width: 41px;
    font-size: 19px;
    margin-right: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 7px;
  margin-bottom: 10px;
}
</style>