import { createApp } from 'vue'
import App from './App.vue'
import PageHome from './components/Home'
import PageOoup from './components/Ooup'
import PageNotFound from './components/NotFound'
// import ethers from "./plugins/ethers";
import {createRouter, createWebHistory} from "vue-router";

import store from './store'

const routes = [
    { path: '/', component: PageHome },
    { path: '/ooup', component: PageOoup },
    {
      path: '/:catchAll(.*)*',
      name: "PageNotFound",
      component: PageNotFound,
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
  })

const app = createApp(App)
app.use(router)
app.use(store)

// app.use(ethers);
app.mount('#app')
